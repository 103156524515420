@use "sass:math";

$body-family: 'Open Sans', Verdana, sans-serif;
// $title-family: 'Ubuntu', sans-serif;
$title-family: 'Open Sans', Verdana, sans-serif;
$subtitle-family: 'Open Sans', sans-serif;

$border-radius: 5px;



@import "node_modules/bulma/bulma";

blockquote {
    clear: both;
}

.very-last {
    color: $grey-light;
    font-size: $size-6;
    text-align: center;
}

.hl-content-mini {
    @extend .content;
    padding: math.div($block-spacing, 2);
    padding-top: 0;
    margin-bottom: $block-spacing;
}

.hl-collections {

    h1.title {
        font-size: $size-4;
    }

    h2.title {
        font-size: $size-5;
    }

}


.hl-text {

    p:last-child {
        margin-bottom: $block-spacing;
    }
}

.content {
    h2.title {
        font-size: $size-5;
    }

    h3.title {
        font-size: $size-6;
    }
}


.meta {
    margin-top: math.div($block-spacing, -2);
    color: $grey-light;
    font-size: $size-7;
    margin-bottom: math.div($block-spacing, 2);
}


/* Pagination number fix - make whole box clickable
*/
ul.pagination-list li.pagination-link {
    padding: 0;
    a {
        padding: 7px 8px;
    }
}

/* Sticky bottom footer hack
*/
#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  & > .hl-main {
    flex: 1;
  }
}

.hl-section {
    @extend .section;
    margin-top: 0;
    padding-top: 0;
}

footer.footer {
    margin-top: $block-spacing;
}

.footer-items {
    display: grid;
    gap: $gap;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 20px;

    .very-bottom {
        grid-column: span 2;
        text-align: center;
    }

    .footer-item-end {
        justify-self: end;
        text-align: right;
    }

    .hl-social-badges .icon {
        font-size: $size-2;
    }
}


/* AMAZING! hack to stop stray end of paragraph lines to wonder underneath float
*/
.content p {
    overflow: hidden;

    &:last-child {
        overflow: visible;
    }
}


.navbar {

    background: $white-bis;
    margin-bottom: math.div($block-spacing, 2);

    .navbar-brand a span {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
        font-size: $size-4;
    }

}


.hl-ux-title {
    font-size: $size-6;
    margin-bottom: 1px;
    padding: 2px;
    background: $white-bis;
}



// .hl-static {
//     position: fixed;
//     @extend .is-4;
// }


/* Panes - fluid grids
*/
.hl-panes {

    display: grid;
    grid-template-columns: repeat(auto-fit, [col-start] minmax(300px, 1fr));
    gap: $block-spacing;
    margin-bottom: $block-spacing;

    &.hl-panes-small {
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    }

}

.hl-panes__item {

    figure {
        overflow: hidden;
    }

    figure img {
        transition: 0.2s all ease-in-out;
    }
 
    a:hover figure img {
        transform: scale(1.01);
    }
    
    .hl-panes__header {
        font-size: $size-6;

        h2, h3 {
            font-family: $body-family;
            font-weight: 600;
            font-size: $size-5;
            // margin-bottom: math.div($block-spacing, 4);
            margin-bottom: 0;
        }

        .hl-panes__meta {
            display: grid;
            grid-template-columns: 2fr 1fr;

            .start {

            }

            .end {
                justify-self: end;
            }

        }

    }

    a:hover .hl-panes__header {
        background: $grey-lightest;
    }

    a .hl-panes__content {
        color: $text;
    }

    .content ul {
        margin-top: 0;
    }
}

.hl-panes__item-compact {

    figure {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: math.div($gap, 2);

        figcaption {
            align-self: stretch;
        }
    }
}


.hl-posts {
    position: relative;
    padding: math.div($block-spacing, 2);
    height: 100%;
    background: $white-bis;

    figcaption {
        position: absolute;
        bottom: 0;
    }


    .hl-posts-item {
        margin-bottom: $block-spacing;

        h2 {
            font-size: $size-4;
        }

        p {
            margin-bottom: math.div($block-spacing, 2);
        }

        .meta {
            text-align: right;
        }

        time {
            font-size: $size-7;
            font-style: italic;
        }
    }
}


/* custom titles
*/
.title.title-little {
    font-size: $size-5;
    font-weight: 100;
    margin-bottom: math.div($block-spacing, 4);
}


/* info box
*/

.hl-infobox {
    margin-bottom: $block-spacing;
    background: $grey-lightest;
    padding: math.div($block-spacing, 4) math.div($block-spacing, 2);
    border-radius: $border-radius;

    .hl-content-link, .hl-credit {
        font-size: $size-7;
        .icon {
            vertical-align: middle;
            font-size: $size-5;
        }
    }

    p {
        margin-bottom: math.div($block-spacing, 2);

        &:last-child {
            margin-bottom: 0;
        }
    }
}


/* grid box
*/
.hl-gridbox {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &>h2 {
        grid-column: span 2;
        font-weight: 600;
        margin-bottom: 2px;
    }

    .hl-gridbox-right {
        justify-self: end;
        text-align: right;
    }

}

/* Figure
*/
figure.hl-figure {
    
    margin-top: math.div($block-spacing, 2) !important;

    &.is-pulled-left {
        @include mobile {
            float: none;
        }
        @include tablet {
            margin-left: 0;
        }
    }

    &.is-pulled-right {
        @include mobile {
            float: none;
        }
        @include tablet {
            margin-right: 0;
        }      
    }

    figcaption {
        font-size: $size-7;

        .hl-arabic {
            font-size: $size-6;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
}

/* just a figure, one image and one figcaption
*/
figure.hl-figure-solo {
    @extend .hl-figure;

    display: table;
    figcaption {
        padding-top: math.div($block-spacing, 2);
        display: table-caption;
        caption-side: bottom;
    }   
}

/* figure with more than one image sharing the same figcaption
*/
figure.hl-figure-grouped {
    &>img {
        display: inline-block;
        vertical-align: top;
        // margin: 0 $gap;
    }
}

figure.hl-figure-300 {
    max-width: 300px;
}

figure.hl-figure-500 {
    max-width: 500px;
}

figure.hl-figure-800 {
    max-width: 800px;
}

figure.hl-figure-1000 {
    max-width: 100%;
}

/* Figure Grid (for mainly images within text)
*/
.hl-figure-grid {
    clear: both;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: $block-spacing;
    margin-bottom: $block-spacing;

    // &>img {
    //     align-self: start;
    // }
    &.cst-2c-1r2r {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        figure:first-child {
            grid-row: span 2;
        }
    }
}

.hl-figure-center figure {
    margin-bottom: $block-spacing;
    margin-left: auto;
    margin-right: auto;
}


/* TABLE
*/
.table {
    &.hl-table {
        tbody th {
            text-align: left;
        }
    }
    .hl-arabic {
        font-size: $size-5;
    }
}

.piia-image-table {

    font-size: $size-7;

    width: 100%;

    figure.image {
        img {
            width: auto;
            max-height: 400px;
        }
    }
}

.hl-piia-full-aspect {
    margin-bottom: $block-spacing;
}

.hl-detailbox .hl-credit {
    text-align: right;
}


/* littlefoot
*/
// .littlefoot {
//     z-index: 100;
// }

